import styled from 'styled-components'
import { useRouter } from 'next/router'
import classNames from 'classnames'

import { useTranslation } from 'i18n-web/i18next'
import { Grid } from 'components/Templates'
import Text from 'components/Atoms/Text'
import Button from 'components/Atoms/Button'
import { useClientResponsive } from 'helpers/hooks'

const Template = styled(Grid)`
  .main {
    margin-top: 0px;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    .main {
      margin-top: 0px;
      &:first-of-type {
        margin-top: 0px;
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
`

const Image = styled.img`
  width: 310px;
  height: auto;
  margin-bottom: 30px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    width: 453px;
  }
`

const Title = styled(Text)`
  text-align: center;
  margin-bottom: 10px;

  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.mobile.h4};
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.h3};
  }
`

const Description = styled(Text)`
  text-align: center;
  margin-bottom: 40px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    max-width: 335px;
  }
`

const GoBackButton = styled(Button)`
  max-width: 340px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    max-width: 291px;
  }
`

const Custom404 = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { isMobile } = useClientResponsive()

  const onButtonClick = () => {
    router.back()
  }

  return (
    <Template>
      <Container>
        <Image src={'/static/images/404.png'} />
        <Title className="primary bold">{t('errorPage.404.title')}</Title>
        <Description
          className={classNames({ small: isMobile, large: !isMobile })}>
          {t('errorPage.404.description')}
        </Description>
        <GoBackButton className="full uppercase" onClick={onButtonClick}>
          {t('errorPage.404.button')}
        </GoBackButton>
      </Container>
    </Template>
  )
}

export default Custom404
